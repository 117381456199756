
.header__wrapper {
    .h1 {
        font-size: 5rem;font-weight: 700;color: var(--dark);
        font-family: var(--heading-font);margin-bottom: 1.15rem;
        font-family: 'Source Sans Pro', Arial ,sans-serif;
    }
    .lead { color: #1f1f1f;; }

    .actions { 
        margin-top: 2rem;
        .btn {
            padding: 1rem 2rem;
            font-size: 1.25rem;
            border: 2px solid var(--primary);
        }
    }
}

.hero_slider__wrapper { 
    position: relative;

    .carousel-item {
        position: relative;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: center center !important;
        .container {
            min-height: 100vh;
        }
        .caption {
            padding: 13rem 0 8rem 0;
            position: relative;
            z-index: 99;

            .h1 {
                font-size: 3rem;font-weight: 700;color: var(--white);
                font-family: var(--heading-font);margin-bottom: 1rem;
                max-width: 62rem;
            }
            .h3 { color: var(--brand);margin-bottom: 1rem; }

            ul {
                li {
                    margin-bottom: .5rem;
                    svg {
                        height: 1rem;width: auto;margin-right: .5rem;
                    }
                }
            }
        }

        &::after {
            content: '';
            display: block;
            background: rgba($color: #000000, $alpha: 0.3);
            height: 100%;width: 100%;
            position: absolute;top: 0%;left: 0%;z-index: 0;
        }
        // .gradient__bottom {
        //     background: linear-gradient(180deg, #5c5c5c 0.29%, rgba(196, 196, 196, 0) 99.87%);
        //     transform: rotate(-180deg);
        //     height: 70%;width: 100%;
        //     position: absolute;bottom: 0%;left: 0%;z-index: 0;
        // }
        // .gradient__center {
        //     background: linear-gradient(180deg, #da863d91 0.49%, rgba(196, 196, 196, 0) 99.87%);
        //     height: 60%;width: 100%;
        //     position: absolute;top: 50%;left: 0%;transform: translateY(-50%);
        // }
    }
  
    .carousel-indicators { 
        z-index: 999;
        [data-bs-target] { 
            width: 14px;
            height: 14px; 
            text-indent: inherit; 
            background-color: #fff; 
            border-radius: 50% !important; 
            border: 0; 
            opacity: 1; 
      
            &.active {
              background-color: var(--brand);
            }
          }
    } 

    .carousel-control-prev,
    .carousel-control-next { display: none; }
}


.short__info__wrapper {
    // background-color: #f4f4f4;
    .icon {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5e9d4;
        color: var(--brand);
        margin-left: auto;
        margin-right: auto;

        svg {
            width: 4rem;
            height: 4rem;
        }
    }
}

.info__card {
    background-color: var(--brand) !important;
    border-radius: .45rem;
    overflow: hidden;

    figure {
        width: 7rem;
        height: 7rem; 
        background-color: #db9639;
        border-radius: 0 2.8rem 2.8rem 0;
        border-right: 2px solid var(--white);
        img {
            height: 4rem;
            width: auto;
        }
    }
}

@media (max-width: 1200px) {
    .hero_slider__wrapper .carousel-item .caption .h1 {
        font-size: 2.25rem;
    }
}

@media (max-width: 768px) {
    .info__card {  
        figure {
            width: 6rem;
            height: 6rem;  
            border-radius: 0 2.8rem 2.8rem 0; 
            img {
                height: 3rem;
                width: auto;
            }
        }
    }
}

@media (max-width: 576px) {
    
    .hero_slider__wrapper .carousel-item .container {
        min-height: auto;
        .caption {
            padding: 4rem 0;
            .h1 {
                font-size: 1.5rem;
                text-align: center;
                margin-bottom: 1rem;
            }
            .h3 {
                font-size: 1.125rem;
                text-align: center;
            }
        }
    } 
    .info__card {  
        figure {
            width: 5rem;
            height: 5rem;  
            border-radius: 0 2.8rem 2.8rem 0; 
            img {
                height: 2.65rem;
                width: auto;
            }
        }
    }
}