

.testimonial__items {
    text-align: center;

    .testi__text {
        font-size: 1rem;
        font-weight: 500; 
        padding-left: 2rem;
    }
    .quote {
        svg {
            width: 2.5rem;
            height: 2.5rem;
            color: var(--brand);
        }
    }
    .student__thumbnail{
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        background-color: var(--brand);
        color: var(--dark);
        display: flex;
        justify-content: center;
        align-items: center; 
    }
    .google {
        height: 2rem;
        width: auto !important;
        margin: 0 auto;
    }
}