
.question__details__modal {
    .modal-dialog {
        max-width: 870px !important;
        

        .modal-title { font-size: 1.25rem; }

        .form-check {
            margin-bottom: .5rem;

            .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
                opacity: 1; 
            }
            .form-check-label {
                font-size: 1rem;
                color: rgb(87, 87, 87);
                padding-left: .25rem;
            }
            .form-check-input{
                width: 20px;
                height: 20px;
                &:disabled { 
                    opacity: 1;
                }
            }
        }

        .svg {
            width: 1.45rem;height: 1.45rem;
        }

        .option__details {
            margin-bottom: 1rem;
            .form-check {
                margin-bottom: 0.125rem;
                .form-check-label { color: #333;font-weight: 600;cursor: text; }
            }
            .desc {
                padding-left: 1.5rem;
                line-height: 1.2;
                font-size: .937rem;
                color: #464646;
            }
        }

        .modal-body {
            max-height: calc(100vh - 8rem);
            overflow-y: auto;
        }
    }
}