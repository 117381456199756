

.contact__wrapper {
    .lead {
        color: var(--brand);font-weight: 400;
    }
    .title {
        font-family: var(--body-font);
    }
    ul {
        li {
            font-weight: 400;
            color: #333;
            a {
                color: #333;
            }
        }

        &.social__links {
            li {
                margin-right: .5rem;
                a {
                    width: 3rem;
                    height: 3rem;
                    border: 1px solid var(--dark);
                    svg {
                        width: 1.25rem;
                        height: 1.25rem;
                    }

                    &:hover {
                        border-color: var(--brand);
                        background-color: var(--brand);
                        color: var(--white);
                    }
                }
            }
        }
    }
    .form__wrapper {
        box-shadow: 0px 8px 33px rgba(0, 0, 0, 0.08);

        .form-control {
            border-radius: 0.5rem;
            height: 3.25rem;
            border-color: #BCBCBC;

            &::placeholder {
                font-size: .875rem;
            }

            &:focus {
                box-shadow: none;
                border-color: var(--dark);
            }
        }

        textarea.form-control {
            height: auto;
        }

        label {
            display: block;
            font-weight: 400;
            color: var(--dark);
            margin-bottom: .25rem;
        }
        
        .btn-primary {
            color: var(--white);
        }
    }
}