

#innerNav {
    position: static;z-index: 999;
    background-color: #fff;
    padding: .5rem 0;
    top: 0%;
    left: 0;
    width: 100%;
    transition: all .3s ease-in-out;

    .navbar-brand img { filter: none; }
    
    .nav-link { 
        font-size: 1.125rem;
        text-transform: capitalize;
        color: var(--dark) !important;
        font-weight: 500;
        opacity: 1;
        padding: .25rem 1.25rem .45rem 1.25rem !important;

       
    }

    .navbar-toggler {
        border: 0;
        padding: .2rem;

        &:focus {
            box-shadow: none;
        }
    }

    .profile__btn {
        background-color: transparent;
        border: 0;
        color: var(--dark);

        &::after { content: none; }
        
        svg {
            width: 1.875rem;
            height: 1.875rem;
        }
        img {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
        }
        &:focus {
            box-shadow: none;
        }
    }

    &.stickyNav {
        background-color: var(--white);
        box-shadow: 0 4px 5px rgba($color: #000000, $alpha: 0.1);
        .nav-link {
            color: var(--dark) !important;
        }
    }
}