@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&family=Raleway:wght@300;400;500;600;700;800&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300&display=swap");
:root {
  --white: #ffffff;
  --primary: #0056d2;
  --brand: #da863d;
  --blue: #838b47;
  --dark: #282723;
  --dark-light: #222;
  --text-color: #464545;
  --link-color: #00cf97;
  --red: #ff4c79;
  --yellow: #ffad41;
  --orange: #FF8C00;
  --border-color: #d6d6d6;
  --body-bg: #fdf6ea;
  --body-font: "Source Sans Pro", sans-serif;
  --heading-font: "Source Sans Pro", sans-serif;
}

// Your variable overrides  ff4800
$primary: #da863d;
$success: #00cf97;

// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
html {
  font-size: 16px;
}
body {
  background-color: #fdf6ea;
  color: #373a3c;
  font-family: var(--body-font);
  font-size: 1rem;
  line-height: 1.4;
  font-style: normal;
  font-weight: 400;
}

// Custom Classes
.fw-medium {
  font-weight: 500;
}
.fw-semibold {
  font-weight: 600;
}
.ptb {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.text-justify {
  text-align: justify;
}
.fs-13 {
  font-size: 13px;
}
.text-justify {
  text-align: justify;
}
.lead-sm {
  font-size: 1.125rem;
}
.bg-gradient {
  background-color: #ebf3ff !important;
}
.bg-light { 
  background-color: #f2f2f2 !important;
}

a {
  color: var(--dark-light);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: var(--brand);
  }

  &.link {
    color: var(--brand);

    &:hover {
      color: var(--blue);
    }
  }
}

// Heading Styles
.top__heading {
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: var(--brand);
}
h2,
.h2 {
  font-size: 2.5rem;
  color: var(--dark-light);
  font-family: var(--heading-font);
}
h3,
.h3 {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--dark);
  font-family: var(--heading-font);
}
h4,
.h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--dark);
  font-family: var(--heading-font);
}
h5,
.h5 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--dark);
  font-family: var(--heading-font);
}

.after {
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 4rem;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: #838c48;
    margin: 0.35rem 0 1rem 0;
  }
}

.btn:focus {
  box-shadow: none;
}

.form-check {
  .form-check-input {
    width: 20px;
    height: 20px;
    &[type="checkbox"] {
      border-radius: 0.125rem !important;
      cursor: pointer;
  
      &:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZWNrIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=") !important;
      }
    }
    &[type="radio"] {
      border-radius: 50% !important;
      cursor: pointer;
  
      &:checked {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iMTAuNSIgZmlsbD0id2hpdGUiIHN0cm9rZT0iI0ZGQUQ0MSIvPgo8Y2lyY2xlIGN4PSIxMSIgY3k9IjExIiByPSI2IiBmaWxsPSIjRkZBRDQxIi8+Cjwvc3ZnPgo=");
        background-color: transparent;
        box-shadow: 0 0 0 0.25rem rgba(218, 134, 61, 0.125);
      }
    }
  
    &:focus {
      box-shadow: none;
    }
  }
  
  .form-check-label {
    padding-left: .5rem;
    font-size: 1.125rem;
    padding-top: .05rem;
    color: #535d8c;
    cursor: pointer;
  }

}

@media (max-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
  h3,
  .h3 {
    font-size: 1.5rem;
  }
  h4,
  .h4 {
    font-size: 1.25rem;
  }
  h5,
  .h5 {
    font-size: 1.125rem;
  }
}

@media (max-width: 992px) {
  html {
    font-size: 15px;
  }
  .ptb {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
  .ptb {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  h2,
  .h2 {
    font-size: 1.5rem;
  }
  h3,
  .h3 {
    font-size: 1.25rem;
  }
  h4,
  .h4 {
    font-size: 1.125rem;
  }
}
