
.legend__list {
    li {
        display: flex;

        .que__item {
            min-width: 11rem;
            padding: .2rem;
            margin-right: .25rem;
        }

        &:not(:last-child) { margin-bottom: .5rem; }
    }
     
}


.question__list{
    padding: 1rem;
    border: 1px solid #cecece;
    margin-top: 1rem;
    background-color: #f2f2f2;
    border-radius: .25rem;
    max-height: 21.35rem;
    overflow-y: auto; 
}
.que__item {
    padding: .5rem;
    border: 1px solid #d4d4d4; 
    background-color: rgb(243, 240, 236);
    display: flex;
    align-items: center;
    border-radius: .25rem;

    .btn {
        font-size: .875rem;font-weight: 600;background-color: #133D2F91;padding: 0rem .625rem .125rem .625rem;
        border-radius: 1rem;margin-right: .5rem;border-color: #133D2F91;color: var(--white);
    }

    &.marked {
        background-color: #f5e9d3;
        border-color: var(--brand);

        .marked {
            padding-left: 1rem; 
            color: var(--orange);

            svg {
                color: var(--orange);
                margin-right: .25rem;
                position: relative;
                top: -.2rem;
            }
        }
    }
}