.courseCarousel {
    .owl-item { padding: .75rem .5rem; }
}
.course__card {
    box-shadow: 0 1px 6px rgba(31,31,31,.12),0 1px 4px rgba(31,31,31,.12);
    border: 0;
    overflow: hidden;
    .title {
        font-size: 1.25rem;
        a {
            color: var(--dark);
    
            &:hover { color: var(--brand); }
        }
    }
    .cats { 
        a {
            margin-right: 1rem;
            display: inline-block;
            font-size: .875rem;
            color: var(--brand);
            text-transform: uppercase;
            position: relative;
            font-weight: 600;

            &:hover {
                color: var(--dark);
            }

            &:not(:last-child)::after {
                bottom: -.125rem;
                content: ",";
                font-size: 1.25rem;
                position: absolute;
                right: -.65rem;
                font-family: "Times New Roman";
                display: inline-block;
                color: #666666;
                text-transform: uppercase;
            }
        }
    }

    .desc {
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .card-footer {
        display: flex;
        flex-direction: row;
        background-color: var(--white);

        .info__box {
            padding: .5rem;
            font-size: .75;
            background-color: #ebebeb;
            margin-right: .5rem;
            color: #333;
            display: inline-flex;
            align-items: center; 

            svg { margin-right: .35rem; }
        }
    }
}