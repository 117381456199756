

footer {
    padding: 3rem 0 0 0;
    background-color: #f5e9d4;

    .title {
        margin-bottom: 1.875rem;
        position: relative;

        &::after, &::before {
            content: '';
            display: block;
            width: 40%;
            height: 1px;
            background-color: #808080;
            position: absolute;
            bottom: -8px;
            left: 0%;
        }
        &::before {
            width: 2.5rem;
            height: 3px;
            bottom: -9px;
            background-color: var(--blue);
            z-index: 1;
            border-radius: 3px;
        }
    }

    .course__list {
        li {
            .thumbnail {
                width: 5rem;
                height: 3.875rem;
    
                img {
                    width: 100%;
                    height: 3.875rem;
                    object-fit: cover;
                    object-position: center center;
                }
            }
            .caption {
                width: calc(100% - 5rem);
                padding-left: 1rem;
    
                .course__title {
                    line-height: 1.1;font-weight: 500;
                    display: block;margin-bottom: .25rem;
                }
    
                .info {
                    font-size: 12px;
                }
            }

            &:not(:last-child) { margin-bottom: 1rem; }
        }
    }

    .link__list {
        li {
            a {
                color: #808080;

                svg {
                    width: 1.125rem;
                    height: 1.125rem;
                }

                &:hover {
                    color: var(--dark);
                }
            }

            &:not(:last-child) {
                margin-bottom: .25rem;
                padding-bottom: .25rem;
                border-bottom: 1px solid #c5c5c5;
            }
        }

        &.address {
            li {
                color: var(--text-color);
                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                    color: var(--blue);
                }
                span {
                    width: calc(100% - 1.5rem);
                    margin-left: auto; 
                    padding-left: .25rem;
                }
                .location {
                    svg {
                        width: 1.125rem;
                        height: 1.125rem;
                        position: relative;
                        top: .25rem;
                    }
                    span {
                        font-size: .937rem;
                        line-height: 1.2;
                    }
                }
                a {
                    color: var(--text-color);
                }
                &:not(:last-child) { 
                    border-bottom: 0;
                }
            }
        }
    }

    .copy__right {
        background-color: var(--blue);
        .developed_by {
            color: var(--white);
            font-weight: 500;
            &:hover { text-decoration: underline; }
        }

        .social__links {
            a { 
                color: var(--white); 
                svg {
                    width: 1.125rem;height: 1.125rem;
                }

                &:hover { 
                    color: var(--dark);
                }
            }
        }
    }
}