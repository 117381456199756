

.exam__card {
    .thumbnail {
        width: 12rem;
    }
    .caption {
        width: calc(100% - 12rem);
        padding-left: 1rem;

        .title a {
            color: var(--brand);

            &:hover { color: var(--dark); }
        }

        .date {
            font-size: .937rem;
        }
        .location {
            font-size: .937rem;
            a {
                color: var(--brand);
            }
        }
    }
}

@media (max-width: 576px) {
    .exam__card .caption {
        width: 100%;
        padding-top: 1rem;
    }
}