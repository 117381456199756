

.profile__box {
    .left__box {
        width: 45%;
        text-align: center;
        img {
            width: 10rem;
            height: 10rem;
            object-fit: cover;
            object-position: center center;
        }
    }
    .right__box {
        width: 55%;

        ul {
            li {
                
                &:not(:last-child) {
                    margin-bottom: .625rem;
                    padding-bottom: .625rem;
                    border-bottom: 1px solid #d6d6d6;
                }
            }
        }
    }
}