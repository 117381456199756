
.table > :not(:first-child) {
    border-top: 1px solid currentColor;
  }
.card {
    box-shadow: 0 3px 8px rgba(112,120,135,.24);
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;

    .card-header {
        color: var(--primary);
        font-size: 1.45rem;
        font-weight: 500;
    }
}

.card-title {
    margin-bottom: 1rem;
}

.dashboard__wraper {
    background-color: #f4f5f6;
    min-height: 100vh;

    .info__card {
        background-color: #fff !important;
        .card-body {  
            .icon {
                width: 4.5rem; 
                height: 4.5rem;
                border-radius: 50%;
                background-color: #e7f0ff;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #296bd9;

                svg {
                    width: 2.75rem;
                    height: 2.75rem;
                }
            }
            .caption {
                width: calc(100% - 5rem);
                padding-left: .75rem;
            }
        }

        &.exams .icon svg { width: 2.25rem;height: 2.25rem; }
        &.correct .icon { background-color: #e6f5fb;color: #1e93b9; }
        &.worng .icon { background-color: #f9f1e6;color: #c99232; }
        &.retest .icon { background-color: #eef5e7;color: #5f9a1f; }
        
    }

    .table {
        th {
            font-size: 1rem;
            font-weight: 600;
            padding: .5rem;
            border-bottom: 1px solid #333;
        }
        td {
            font-size: 1rem;
            padding: .5rem;
        }

        a {
            font-weight: 600;
            color: var(--link-color);
        }

        .status {
            padding: .12rem .625rem .25rem .625rem;
            border: 1px solid var(--link-color);
            color: var(--link-color);
            border-radius: 1rem;
            font-size: .937rem;
            min-width: 5.5rem;
            display: inline-block;
            text-align: center;

            &.pending {
                border-color: var(--yellow);
                color: var(--yellow);
            }
        }

        .btn-primary {
            border-color: var(--blue);
            background-color: var(--blue);
            color: var(--white);
            font-size: 14px;
            font-weight: 400;
            border-radius: 2rem;
            padding: .2rem 1rem;
            &:hover {
                filter: brightness(115%);
            }
        }
    }
}