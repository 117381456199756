
.result__data {
    max-width: 400px;
    border: 1px solid var(--border-color);
    li {
        display: flex;
        align-items: center; 
        padding: .625rem;
        svg {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: .5rem;
            color: var(--brand);
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--border-color); 
        }
        &.lg_icon {
            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}

.text-underline {
    text-decoration: underline;
}


// Detailed Result Page Styles 
.detailed__result__wrapper {
    .card {
        .card-header {
            padding-top: .875rem;
            padding-bottom: .875rem;
            cursor: pointer; 
        }
    }
    .filter__wrapp {
        max-width: 300px;

        .form-check {
            .form-check-label {
                padding-left: .25rem; 
                cursor: pointer;
                color: #333;
            }

            &:not(:last-child) {
                margin-bottom: .25rem;
            }
        }
    }

    .filter__content {
        .table {
            td, th {
                text-align: center;
                vertical-align: middle;

                &:first-child { text-align: left; }
                &:last-child { text-align: right; }
            } 
        }
        .fail, .pass {
            width: 1.5rem;
            height: 1.5rem;
            color: var(--red);
        }
        .pass { color: var(--link-color); }

        .question__btn {
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 1;
            padding: .35rem .875rem .5rem .875rem;
        }
    }
}