

.page__header {
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    padding: 10rem 0 6rem 0;
    h2 {
        font-size: 3.5rem;
        margin-bottom: 0; 
        text-transform: uppercase;
    }
}