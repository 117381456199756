

.navbar {
    position: fixed;z-index: 999;
    background-color: transparent;
    padding: 1rem 0;
    top: 0%;
    left: 0;
    width: 100%;
    transition: all .3s ease-in-out;

    .navbar-brand img { height: 30px;width: auto;filter: contrast(0) brightness(10); }
    
    .nav-link { 
        font-size: 1.125rem;
        text-transform: capitalize;
        color: var(--white) !important;
        font-weight: 500;
        opacity: 1;
        padding: .25rem 1.25rem .45rem 1.25rem !important;

        &.login {
            border: 1px solid var(--brand);
            border-radius: 2rem;
            margin-left: .5rem;

            &:hover {
                background-color: var(--brand);
                color: var(--white) !important;
            }
        }
    }

    .navbar-toggler {
        border: 0;
        padding: .2rem;

        &:focus {
            box-shadow: none;
        }
    }

    &.stickyNav {
        padding: .5rem;
        background-color: var(--white);
        box-shadow: 0 4px 5px rgba($color: #000000, $alpha: 0.1);
        .navbar-brand img { filter: none; }
        .nav-link {
            color: var(--dark) !important;
        }
    }
}


@media (max-width:991.99px) {
    .navbar .nav-link{
        text-align: center;
        .login { 
            display: inline-block;
            width: 7rem;
        }
    }
}