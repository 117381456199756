
.progress__wrapp {
    border-radius: 3rem;
    padding: 1rem 2rem;
    .progress__box {
        width: calc(100% - 11rem);
        padding-right: .5rem;

        .pro__title {
            margin-bottom: .9rem;
        }

        .progress {
            border-radius: 1rem;
            height: .625rem;
            position: relative;

            .active {
                position: absolute;
                top: 0%;
                left: 0%;
                height: 100%;
                background-color: var(--link-color);
            }
        }
    }
    .time{
        width: 11rem;
        padding-left: 1rem;

        .clock {
            color: var(--red);
            font-size: 1.75rem;
            font-weight: 500;
            line-height: 1;
        }
    }
}


.question__wrapp {
    border-radius: 3rem;
    background-color: #fff;
    margin-top: 2rem;

    .action__btns {
        .btn {
            font-size: .875rem;padding: .125rem .75rem .25rem .75rem;text-transform: uppercase;margin-left: .25rem;

            &.btn-secondary {
                background-color: var(--yellow);
                border-color: var(--yellow);
                color: var(--white);

                &:hover {
                    filter: brightness(115%);
                }
            }

            &.btn-primary {
                background-color: #435f85;
                border-color: #435f85;
                color: var(--white);
                &:hover {
                    filter: brightness(115%);
                }
            }
        }
    }

    h5 {
        color: #535d8c;
        font-weight: 500;
    }

    .form__wrapp {
        margin-top: 2rem;
        .form-check {
            margin-bottom: .75rem;
            padding-left: 3rem; 

           
        }

        .btns {
            margin-top: 5rem;
            .btn {
                // border-radius: 2rem;
                padding: .5rem 1.25rem;
                min-width: 7rem;
                font-weight: 600;
                margin: 0% .25rem;
                transition: all .3s ease-in-out;
 
                &.btn-primary {
                    background-color: var(--link-color);
                    border-color: var(--link-color);
                    color: var(--white);

                    &:hover {
                        filter: brightness(115%);
                    }
                }
                &.btn-outline-dark { 
                    border-color: #b2b2b2;
                    color: #b2b2b2;

                    &:hover {
                        filter: brightness(115%);
                    }
                }
            }
        }
    }
}

.overview__wrapp {
    .info__box {
        width: 8rem;
        height: 8rem;
        background-color: #535d8c;
        margin: .5rem;

        &.correct { background-color: var(--link-color); }
        &.worng { background-color: var(--red); }
        &.pending { background-color: var(--yellow); }
    }
}

.hintModal {
    .modal-dialog {
        max-width: 700px;
    }
}