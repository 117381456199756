

.pricing__card {
    background-color: #f5e9d3;
    padding: 2rem 1.5rem;
    border-radius: .5rem;
    .title {
        color: var(--brand);
    }

    ul {
        li {
            color: #808080;
            svg {
                color: #22bb33;
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }

    .btn-outline-dark {
        border-width: 2px;
        border-radius: 0%;
        padding: .75rem 2.5rem;
    }
}

.pricingCarousel {
    .owl-nav {
        position: absolute;
        top: -5rem;
        right: 0%;

        .owl-prev, .owl-next {
            width: 2.5rem;
            height: 2.5rem;
            border: 2px solid var(--dark) !important;
            border-radius: 0%;
            background-repeat: no-repeat !important;
            background-size: 1.5rem 1.5rem !important;
            background-position: center center !important;
            span { display: none; }
        }
        .owl-prev {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZXZyb24tbGVmdCI+PHBvbHlsaW5lIHBvaW50cz0iMTUgMTggOSAxMiAxNSA2Ij48L3BvbHlsaW5lPjwvc3ZnPg==') !important;
        }
        .owl-next {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZXZyb24tcmlnaHQiPjxwb2x5bGluZSBwb2ludHM9IjkgMTggMTUgMTIgOSA2Ij48L3BvbHlsaW5lPjwvc3ZnPg==') !important;
        }
    }
}