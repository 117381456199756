

.auth__wrapp {
    border: 1px solid #eee;
    box-shadow: 2px 2px 8px rgba($color: #000000, $alpha: 0.1);
    max-width: 500px;
    border-radius: .5rem;
    background-color: #fff; 

    label {
        font-size: 1.125rem;
        padding-left: 1rem;
    }

    .form-control {
        border-radius: 3rem;
        height: 3.5rem;
        padding: .5rem 1rem .5rem 2rem;
        border-color: #d8d8d8;

        &:focus {
            box-shadow: none;
            border-color: var(--brand);
        }
    }

    .btn-primary {
        border-radius: 3rem;
    }

    .form-check {
        margin-left: .875rem;
    }
    
    .form-check-label {
        padding-left: 0.25rem;
    }

    .forpass {
        color: var(--brand);
        font-weight: 500;

        &:hover {
            color: var(--blue);
            text-decoration: underline;
        }
    }
}